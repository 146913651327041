// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCuXYB8xE9XgQzIOMMRJAVfyiDui1vXr2M",
    authDomain: "fixitnowapp.firebaseapp.com",
    databaseURL: "https://fixitnowapp.firebaseio.com",
    projectId: "fixitnowapp",
    storageBucket: "fixitnowapp.appspot.com",
    messagingSenderId: "69100913669",
    appId: "1:69100913669:web:398f7cb6b95c56d429c86c",
    measurementId: "G-8HXCSRWZLL"
  }
};
