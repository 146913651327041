import { Injectable } from '@angular/core';
import { Observable,Subject} from 'rxjs';


@Injectable()
export class LoadingService {


  private subject = new Subject<any>();

  constructor() { }

    showLoading() {
        this.subject.next(true);
    }

    hideLoading() {
        this.subject.next(false);
    }

    getLoading(): Observable<any> {
        return this.subject.asObservable();
    }

}
