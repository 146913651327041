import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatButton, MatListItem, MatList} from '@angular/material';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';



@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

	dialogRef: any;
  inputdata:any;
  constructor(
		public dialog: MatDialog,
  	) { }

  ngOnInit() {
  }


  openAddonDialog(dialogTitle: string) {
    this.dialogRef = this.dialog.open(DialogAddonDialog, {
     width: '50%',
     data: {title: dialogTitle, extraPay:{amount:0,remark:""}}
   });

    return new Promise((resolve, reject) => {

       this.dialogRef.afterClosed().subscribe(result => {
         console.log('Dialog result:',result);
          resolve(result);
       });
     });
 }


  openDialog(dialogTitle: string, dialogContent : string) {
     this.dialogRef = this.dialog.open(DialogDataDialog, {
      width: '50%',
      data: {title: dialogTitle, content : dialogContent}
    });

     return new Promise((resolve, reject) => {

        this.dialogRef.afterClosed().subscribe(result => {
          console.log('Dialog result:',result);
           resolve(result);
        });
      });
  }

  openContentDialog(dialogTitle: string, dialogContent : string) {
     this.dialogRef = this.dialog.open(DialogContentDialog, {
      width: 'auto',
      data: {title: dialogTitle, content : dialogContent}
    });

     return new Promise((resolve, reject) => {

        this.dialogRef.afterClosed().subscribe(result => {
          console.log('Dialog result:',result);
           resolve(result);
        });
      });
  }

  openDialogList(dialogTitle: string, dialogContent : string) {
     this.dialogRef = this.dialog.open(DialogListDialog, {
      width: '50%',
      data: {title: dialogTitle, content : dialogContent}
    });


        this.dialogRef.afterClosed().subscribe(result => {
          console.log('Dialog result:',result);
        });
  }

  openDialogDetail(dialogTitle: string, dialogContent : string) {
    console.log(dialogContent);
     this.dialogRef = this.dialog.open(DialogDetailDialog, {
      width: '50%',
      data: {title: dialogTitle, content : dialogContent}
    });


        this.dialogRef.afterClosed().subscribe(result => {
          console.log('Dialog result:',result);
        });
  }

  openDialogSuccess(dialogContent : string) {
     this.dialogRef = this.dialog.open(DialogSuccessDialog, {
      width: '25%',
      data: {content : dialogContent}
    });


        this.dialogRef.afterClosed().subscribe(result => {
          console.log('Dialog result:',result);
        });
  }


  openInputDataDialog(dialogTitle:string ,dialogContent : string) {
    this.dialogRef = this.dialog.open(DialogInputDataDialog, {
      width: '50%',
      data: {title:dialogTitle, content:dialogContent,inputdata:this.inputdata}
    });
    return new Promise((resolve, reject) => {

       this.dialogRef.afterClosed().subscribe(result => {
         console.log('Dialog result:',result);
        resolve(result);
        });

      });
 }


  closeDialog(){
  	this.dialogRef.close();
  }


}


@Component({
  selector: 'dialog-addon',
  templateUrl: 'dialog-addon.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogAddonDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogAddonDialog>
    ) {

  }
  cancel(){
    console.log("cancel");
    this.dialogRef.close();
  }

  submit(){
    console.log(this.data);
    this.dialogRef.close(this.data.extraPay);
  }

}



@Component({
  selector: 'dialog-data-dialog',
  templateUrl: 'dialog-data-dialog.html',
})
export class DialogDataDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}

@Component({
  selector: 'dialog-content-dialog',
  templateUrl: 'dialog-content-dialog.html',
})
export class DialogContentDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}

@Component({
  selector: 'dialog-list-dialog',
  templateUrl: 'dialog-list-dialog.html',
})
export class DialogListDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public router : Router,

    ) {}

  goToEditChecklist(checklistId){
      this.router.navigate(['/edit-checklist-step/' + checklistId]);
  }

  goToAddChecklist(){
      this.router.navigate(['/add-checklist-step/']);
  }
}


@Component({
  selector: 'dialog-detail-dialog',
  templateUrl: 'dialog-detail-dialog.html',
})
export class DialogDetailDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}

@Component({
  selector: 'dialog-success-dialog',
  templateUrl: 'dialog-success-dialog.html',
})
export class DialogSuccessDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}

@Component({
  selector: 'dialog-dataInput-dialog',
  templateUrl: 'dialog-dataInput-dialog.html',
})
export class DialogInputDataDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}